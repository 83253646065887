<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {pharmacyService} from "@/services/pharmacy.service";

/**
 * Blank page component
 */
export default {
  page: {
    title: "Gardes Pharmaceutiques",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      editing: false,
      drugs: [],
      title: "Médicaments",
      items: [
        {
          text: "Accueil",
          href: "/"
        },
        {
          text: "Médicamments",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "name", label: "Nom", sortable: true },
        { key: "unit", label: "Unité", sortable: true },
        { key: "forme", label: "Forme", sortable: true },
        { key: "price", label: "Prix", sortable: true },
        { key: "actions", thStyle: { width: "10%" } },
      ],
      submitted: false
    };
  },
  computed: {
    rows() {
      return this.drugs.length;
    }
  },
  mounted() {
    this.getDrugs();
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDrugs() {
      this.drugs = await pharmacyService.getDrugs();
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <div v-if="!editing" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Liste des médicaments</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Affiche
                    <b-form-select class="form-control form-control-sm form-select form-select-sm ms-1 mx-1" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entrées
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Rechercher:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Rechercher..."
                        class="form-control form-control-sm ml-2 ms-1"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                  :items="drugs"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >
                <template #cell(price)="row">
                  {{ row.value }} FCFA
                </template>

                <template #cell(actions)="row">
                  <b-button size="sm" class="bg-light">
                    Voir
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

